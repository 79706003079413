import { isClientSide } from "@utils"

/*
  Browser Support Utilities
*/
export const hasLocalStorageEnabled = (): boolean => {
  // Source: Modernizer
  // https://github.com/Modernizr/Modernizr/blob/94a690127904ed7d85679970257e76f3528b4b1c/modernizr.js#L794
  let test = "test"
  if (!(typeof window !== "undefined")) return false
  try {
    window.localStorage.setItem(test, test)
    window.localStorage.removeItem(test)
    return true
  } catch (e) {
    return false
  }
}

/*
  UX Utilities
*/
export const smoothScrollToElement = (elementId: string): void => {
  if (!isClientSide()) return
  const element = document.getElementById(elementId)
  if (!element) return
  const { top: elementTop } = element.getBoundingClientRect()
  window.scrollTo({
    top: elementTop + window.scrollY - 120, // "SiteNav height"
    behavior: 'smooth',
  })
  window.history.pushState("", "", `#${elementId}`)
}

export const smoothScrollToRef = (elementRef?: { current?: HTMLElement }): void => {
  if (!isClientSide()) return
  if (!elementRef || !elementRef.current) return
  window.scrollTo({
    top: elementRef.current.getBoundingClientRect().top,
    behavior: 'smooth',
  })
}
