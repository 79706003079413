import React, { ReactElement, ReactNode, useMemo } from "react"

// Shared Components
import SiteFooter from "../site/SiteFooter/SiteFooter"
import SiteFooterSmall from "../site/SiteFooterSmall/SiteFooterSmall"
import SiteNav from "@components/site/SiteNav/SiteNav"

// Sub-components
import Beacon from "@components/Beacon/Beacon"
import FixedCtaBar from "@components/FixedCtaBar/FixedCtaBar"
import HashHandler from "./components/HashHandler/HashHandler"
import NinetailedListener from "./components/NinetailedListener/NinetailedListener"
import QueryStringHandler from "./components/QueryStringHandler/QueryStringHandler"

// Consts
import {
  USE_SMALL_FOOTER_PATHS,
  USE_STATIC_HEADER_PATHS,
  HIDE_FOOTER_PATHS,
  INCLUDE_FIXED_CTA_BAR_PATHS_REGEX,
} from "./Layout.constants"

// Types/interfaces
interface LayoutProps {
  children: ReactNode
  path: string
  template?: string
}

interface LayoutInternalVars {
  hideFooter: boolean
  includeFixedCtaBar: boolean
  isHomePage: boolean
  useSmallFooter: boolean
  useStaticHeader: boolean
}

// Component
const Layout = ({ children, path, template }: LayoutProps): ReactElement => {
  const {
    hideFooter,
    includeFixedCtaBar,
    isHomePage,
    useSmallFooter,
    useStaticHeader,
  }: LayoutInternalVars = useMemo(
    (): LayoutInternalVars => ({
      hideFooter: HIDE_FOOTER_PATHS.includes(path),
      includeFixedCtaBar: !!path.match(INCLUDE_FIXED_CTA_BAR_PATHS_REGEX),
      isHomePage: path === "/",
      useSmallFooter: USE_SMALL_FOOTER_PATHS.includes(path),
      useStaticHeader: USE_STATIC_HEADER_PATHS.includes(path),
    }),
    [path, template]
  )

  return (
    <>
      <HashHandler />
      <QueryStringHandler />
      <NinetailedListener path={path} />
      <SiteNav
        isStatic={useStaticHeader}
        includeLoginButton={isHomePage}
      />
      <main>{children}</main>
      {!hideFooter && (useSmallFooter ? <SiteFooterSmall /> : <SiteFooter />)}
      {includeFixedCtaBar && <FixedCtaBar />}
      <Beacon />
    </>
  )
}

export default Layout
