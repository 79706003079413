import React, { FC } from "react"
import styled, { keyframes } from "styled-components"

import { generateConditionalClassName } from "@utils"
import Icon, { ICON_TYPES } from "@components/Icon/Icon"

const LoadingSpinKeyframes = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`

const SubmissionMessageDIV = styled.div`
  background: var(--color--success-background-light);
  border-radius: var(--border-radius--M);
  border: 1px solid var(--color--success-border);
  color: var(--color--success-text);
  height: var(--button--height-L);
  line-height: var(--button--height-L);
  padding: 0 var(--input--padding-x-L);
  text-align: left;
  width: 100%;

  > span {
    display: flex;
    align-items: center;
    height: 100%;

    p {
      margin: 0;
    }
  }

  .Icon {
    display: inline-block;
    margin-right: var(--spacing--base-0-5);

    svg {
      vertical-align: top;
    }

    &--loading {
      animation: linear 3s infinite ${LoadingSpinKeyframes};
    }
  }

  &.has-error {
    background: var(--color--error-background-light);
    border-color: var(--color--error-border);
    color: var(--color--error-text);
  }

  .is-compact & {
    height: var(--button--height-M);
    line-height: var(--button--height-M);
  }
`

type SubmissionMessageProps = {
  successMessage?: string,
  errorMessage?: string,
  isSubmitting?: boolean,
}

const SubmissionMessage: FC<SubmissionMessageProps> = ({
  successMessage,
  errorMessage,
  isSubmitting,
}) => {
  const hasError = !isSubmitting && !!errorMessage
  return (
    <SubmissionMessageDIV
      className={generateConditionalClassName({
        "has-error": hasError,
      })}
    >
      {!isSubmitting && successMessage && (
        <span>
          <Icon
            color="var(--color--success-icon)"
            size="22px"
            type={ICON_TYPES["checkmark"]}
          />
          <span dangerouslySetInnerHTML={{ __html: successMessage }} />
        </span>
      )}
      {!isSubmitting && errorMessage && (
        <span>
          <Icon
            color="var(--color--error-icon)"
            size="22px"
            type={ICON_TYPES["alert-error"]}
          />
          <span dangerouslySetInnerHTML={{ __html: errorMessage }} />
        </span>
      )}
      {isSubmitting && (
        <span>
          <Icon
            color="var(--color--success-icon)"
            size="22px"
            type={ICON_TYPES["loading"]}
          />
          Sending...
        </span>
      )}
    </SubmissionMessageDIV>
  )
}

export default SubmissionMessage
