import { useEffect, useState } from "react"

import { isClientSide } from "@utils"

const SCROLL_UP_THRESHOLD = 100
const SCROLL_DOWN_THRESHOLD = 200

export enum SCROLLED_AND_VISIBLE_STATES {
  HIDING = "HIDING",
  SHOWING = "SHOWING",
  UNINITIALIZED = "UNINITIALIZED",
}

export function useScrolledAndVisible(isOpen: boolean): SCROLLED_AND_VISIBLE_STATES {
  const [scrolledAndVisibleState, setScrolledAndVisibleState] = useState<SCROLLED_AND_VISIBLE_STATES>(SCROLLED_AND_VISIBLE_STATES.UNINITIALIZED)
  const [scrollY, setScrollY] = useState<number>(0)
  const [scrollYStartedScrollingUp, setScrollYStartedScrollingUp] = useState<number>(0)
  const [scrollYStartedScrollingDown, setScrollYStartedScrollingDown] = useState<number>(0)

  useEffect(() => {
    if (!isClientSide()) return

    const SCROLL_START_POSITION = window.innerHeight * 0.5

    if (isOpen) {
      setScrollYStartedScrollingUp(0)
      setScrollYStartedScrollingDown(0)
      setScrolledAndVisibleState(
        window.scrollY <= SCROLL_START_POSITION ?
          SCROLLED_AND_VISIBLE_STATES.UNINITIALIZED :
          SCROLLED_AND_VISIBLE_STATES.HIDING
      )
      return
    }

    const updateScrollY = () => {
      setScrollY(window.scrollY)

      // If we return to the top of the page, reset
      if (
        window.scrollY <= 0
      ) {
        setScrollYStartedScrollingUp(0)
        setScrollYStartedScrollingDown(0)
        setScrolledAndVisibleState(SCROLLED_AND_VISIBLE_STATES.UNINITIALIZED)
        return
      }

      // If we start scrolling up, mark the spot
      if (
        scrolledAndVisibleState !== SCROLLED_AND_VISIBLE_STATES.SHOWING &&
        window.scrollY > SCROLL_START_POSITION &&
        window.scrollY < scrollY &&
        !scrollYStartedScrollingUp
      ) {
        setScrollYStartedScrollingUp(window.scrollY)
        return
      }

      // If we've scrolled up the SCROLL_UP_THRESHOLD amount past the marked spot, go visible
      if (
        scrollYStartedScrollingUp - window.scrollY >= SCROLL_UP_THRESHOLD
      ) {
        setScrolledAndVisibleState(SCROLLED_AND_VISIBLE_STATES.SHOWING)
        setScrollYStartedScrollingUp(0)
        return
      }

      // If we start scrolling down, and we're visible, mark the spot
      if (
        scrolledAndVisibleState === SCROLLED_AND_VISIBLE_STATES.SHOWING &&
        window.scrollY > scrollY &&
        !scrollYStartedScrollingDown
      ) {
        setScrollYStartedScrollingDown(window.scrollY)
        return
      }

      // If we're scrolling down and we're visible and the spot is marked, hide it again
      if (
        scrolledAndVisibleState === SCROLLED_AND_VISIBLE_STATES.SHOWING &&
        scrollYStartedScrollingDown > 0 &&
        (window.scrollY - scrollYStartedScrollingDown >= SCROLL_DOWN_THRESHOLD)
      ) {
        setScrolledAndVisibleState(SCROLLED_AND_VISIBLE_STATES.HIDING)
        setScrollYStartedScrollingDown(0)
        return
      }

      // If we hit the bottom of the page, hide!
      if (
        scrolledAndVisibleState === SCROLLED_AND_VISIBLE_STATES.SHOWING &&
        window.scrollY + window.innerHeight >= document.body.scrollHeight
      ) {
        setScrolledAndVisibleState(SCROLLED_AND_VISIBLE_STATES.HIDING)
        setScrollYStartedScrollingDown(0)
        return
      }
    }

    window.addEventListener("scroll", updateScrollY)

    return () => {
      window.removeEventListener("scroll", updateScrollY)
    }
  }, [
    isOpen,
    scrollY,
    scrollYStartedScrollingUp,
    scrollYStartedScrollingDown,
  ])

  return scrolledAndVisibleState
}
