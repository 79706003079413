import { Script } from "gatsby"
import React, { FC } from "react"

import RedditScript from "./RedditScript/RedditScript"
import UnifyScript from "./UnifyScript/UnifyScript"

import {
  INCLUDE_JQUERY_PATHS,
  INCLUDE_UNIFY_PATHS,
} from "./OptionalScripts.constants"

interface OptionalScriptsProps {
  path: string
}

const OptionalScripts: FC<OptionalScriptsProps> = ({ path }) => {
  const includeJquery = INCLUDE_JQUERY_PATHS.includes(path)
  const includeReddit = path.includes("/see-it-in-action/")
  const includeUnify = !!INCLUDE_UNIFY_PATHS.find(p => path.includes(p))

  return (
    <>
      {includeJquery && (
        <Script
          src="//code.jquery.com/jquery-3.6.0.slim.min.js"
          integrity="sha384-Qg00WFl9r0Xr6rUqNLv1ffTSSKEFFCDCKVyHZ+sVt8KuvG99nWw5RNvbhuKgif9z"
          crossOrigin="anonymous"
        />
      )}
      {includeReddit && <RedditScript />}
      {includeUnify && <UnifyScript />}
    </>
  )
}

export default OptionalScripts
