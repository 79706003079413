import { useProfile } from "@ninetailed/experience.js-gatsby"
import { isClientSide } from "@utils"
import { FC, useEffect, useState } from "react"

const NinetailedListener: FC<{ path: string }> = ({ path }) => {
  const { status } = useProfile()
  const [cbpVariantIndex, setCbpVariantIndex] = useState<number | undefined>()

  useEffect(() => {
    if (!isClientSide() || status !== "success") return

    const cbpExperience = (window.ninetailed?.experiences || []).find(e => e && e.experienceId === "OiylN10l5R4rUKvCJTyua") || {}

    const { variantIndex } = cbpExperience

    if (variantIndex !== 0 && variantIndex !== 1) return

    setCbpVariantIndex(variantIndex)
  }, [status])

  useEffect(() => {
    if (!isClientSide() || cbpVariantIndex === undefined) return

    //
    // Update any trial links to include `?hs_tvar=50-{A/B}` depending on the Ninetailed variant served
    //
    const allTrialLinks = Array.from(document.querySelectorAll<HTMLAnchorElement>(`a[href*="/members/"]`))

    allTrialLinks.forEach(link => {
      const splitUrl = link.href.split("?")
      const urlParams = new URLSearchParams(splitUrl[1])
      urlParams.set("hs_tvar", cbpVariantIndex === 1 ? "50-B" : "50-A")
      const newUrl = `${splitUrl[0]}?${urlParams.toString()}`
      link.href = newUrl
    })

    //
    // Append the variant data to the Get a Demo HubSpot form
    //
    const getADemoInput = document.querySelector<HTMLInputElement>(`input#eef75200-005c-4f15-bfd6-54108afbf9de-cbp_variant`)
    if (getADemoInput) {
      const getADemoInputValue = cbpVariantIndex === 1 ? "cbp" : "ubp"
      Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, "value")
        .set.call(getADemoInput, getADemoInputValue)
      getADemoInput.dispatchEvent(new Event("change", { bubbles: true }))
    }
  }, [cbpVariantIndex, path])

  return null
}

export default NinetailedListener
