import React, { ChangeEvent, FC, useEffect, useRef, useState } from "react"

import { HubspotFormInputFragmentFragment } from "@graphqlTypes"

import { isClientSide } from "@utils"
import ValidationIcon from "../ValidationIcon/ValidationIcon"


type FileUploadFieldProps = {
  className?: string,
  field: HubspotFormInputFragmentFragment,
  id: string,
  isCompact?: boolean,
  isDisabled?: boolean,
  isInvalid: boolean,
  validateField: (el: HTMLInputElement) => void,
}

const FileUploadField: FC<FileUploadFieldProps> = ({
  className,
  field,
  isDisabled,
  isInvalid,
  validateField,
}) => {
  const FileEl = useRef<HTMLInputElement | null>(null)
  const [file, setFile] = useState<any>("")
  const [fileUrl, setFileUrl] = useState<string>("")
  const [hasError, setHasError] = useState<boolean>(false)

  useEffect(() => {
    if (
      !isClientSide() ||
      !FileEl.current ||
      !FileEl.current.files ||
      !FileEl.current.files[0]
    ) return

    const Reader = new FileReader()
    const filename = FileEl.current.files[0].name

    if (!Reader) return

    const handleLoad = () => {
      fetch("/api/hubspot/uploadFile", {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({
          file: Reader.result,
          filename,
        })
      })
        .then(res => res.json())
        .then(data => {
          if (!data.success) {
            setHasError(true)
            return
          }

          setFileUrl(data.fileUrl)
        })
        .catch(err => {
          console.error(err)
          setHasError(true)
        })
    }

    Reader.addEventListener("load", handleLoad)

    Reader.readAsDataURL(FileEl.current.files[0])

    return () => {
      Reader.removeEventListener("load", handleLoad)
    }
  }, [file])

  const handleChangeFile = (e: ChangeEvent<HTMLInputElement>) => {
    setFile(e.target.value)
  }

  return (
    <div
      className={className}
      style={{
        alignSelf: "flex-start",
        position: "relative"
      }}
    >
      <input
        accept="image/*,.pdf"
        className={isInvalid ? "has-error" : ""}
        disabled={isDisabled}
        name={`${field.name || ""}__FILE`}
        onChange={handleChangeFile}
        ref={FileEl}
        type="file"
        value={file}
      />
      <input
        className="fileUploadField"
        disabled={isDisabled}
        name={field.name || ""}
        onChange={(e) => validateField(e.target)}
        required={!!field.required}
        style={{
          position: "absolute",
          opacity: 0,
          pointerEvents: "none",
        }}
        type="text"
        value={fileUrl}
      />
      {isInvalid && <ValidationIcon fieldName={field.name || ""} />}
    </div>
  )
}

export default FileUploadField
