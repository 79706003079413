import React, { FC, ReactNode } from "react"
import styled from "styled-components"

const ContentBreakoutDIV = styled.div`
  // negative margins to break out of the Content component's max-width and provide a 100vw div to start from!
  margin: 0 calc((50vw - min(var(--container-width), var(--text-max-width)) / 2) * -1);
`

const ContentBreakout: FC<{ children: ReactNode, className?: string }> = ({
  children,
  className
}) => <ContentBreakoutDIV className={className}>{children}</ContentBreakoutDIV>

export default ContentBreakout
