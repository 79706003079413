import styled from 'styled-components';

import { MEDIA_QUERIES } from "@constants";

export const ContentDIV = styled.div`
  h1,
  h2,
  h3,
  h4,
  h5 {
    -webkit-font-smoothing: antialiased;
  }

  h2 {
    margin-top: var(--spacing--M);
    margin-bottom: calc(var(--font-size--2XL) * 0.625);
    font-size: var(--font-size--2XL);
    font-weight: var(--font-weight--medium);
  }

  h3 {
    margin-top: var(--spacing--base-2);
    margin-bottom: calc(var(--font-size--L) * 0.625);
    font-size: var(--font-size--L);
    font-weight: var(--font-weight--medium);
  }

  h4 {
    margin-top: var(--spacing--base-2);
    margin-bottom: calc(var(--font-size--M) * 0.625);
    font-size: var(--font-size--M);
    font-weight: var(--font-weight--medium);
  }

  h5 {
    margin-top: var(--spacing--base-2);
    margin-bottom: calc(var(--font-size--S) * 0.625);
    font-size: var(--font-size--S);
    font-weight: var(--font-weight--medium);
  }

  p {
    color: var(--color--text-default);
    margin-bottom: var(--spacing--base-1-25);

    &:last-child {
      margin-bottom: var(--spacing--M);
    }

    &:empty {
      display: none;
    }
  }

  ol,
  ul {
    margin-bottom: var(--spacing--XS);
  }

  ol,
  ul {
    padding-left: 1em;

    li {
      line-height: var(--line-height--relaxed);
      margin-bottom: var(--spacing--XS);

      p:last-child {
        margin-bottom: 0;
      }
    }

    ol,
    ul {
      margin-top: var(--spacing--S);
    }
  }

  ul {
    list-style: disc;

    li::marker {
      color: var(--color--text-light);
    }
  }

  ul ul {
    list-style: circle;
    padding-left: var(--spacing--S);
    margin-top: var(--spacing--XS);
  }

  ol {
    counter-reset: ol-counter;
    list-style: none;

    > li {
      position: relative;
      counter-increment: ol-counter;

      &::before {
        content: counter(ol-counter) ".";
        font-size: inherit;
        display: block;
        position: absolute;
        right: calc(100% + 0.25em);
        top: 1px;
        font-weight: var(--font-weight--medium);
        font-size: var(--font-size--M);
        line-height: calc(var(--font-size--L) * var(--line-height--relaxed));
      }
    }

    &[type="a"] > li::before {
      content: counter(ol-counter, lower-alpha) ".";
    }

    &[type="i"] > li::before {
      content: counter(ol-counter, lower-roman) ".";
    }
  }

  a:not(.CTA),
  button:not(.submit-button) {
    color: var(--color--text-dark);
    border-bottom: 1px solid var(--color--text-dark);
    cursor: pointer;

    &:hover {
      color: var(--color--action-hovered);
      border-bottom-color: var(--color--action-hovered);
    }
  }

  .HubspotForm a,
  .HubspotForm button {
    border-bottom: none;
  }

  strong,
  b, p b {
    color: var(--color--text-dark);
    font-weight: var(--font-weight--medium);
  }

  u {
    text-decoration: none;
  }

  pre {
    max-width: 100%;
    overflow: scroll;
  }

  video {
    display: block;
    margin: 60px auto;
    max-width: 100%;
    width: auto;
    height: auto;
  }

  pre,
  code {
    background: var(--color--clay-100);
    border-radius: var(--border-radius--S);
    border: 1px solid var(--color--clay-200);
    color: var(--color--text-dark);
    font-family: var(--font-family--mono-code);
    padding: var(--spacing--base-1);
  }

  p > code {
    display: inline-block;
    padding: 0 var(--spacing--base-0-5);
    font-size: 0.9em;
  }

  pre code {
    background: transparent;
    border-radius: 0;
    border: none;
    padding: 0;
  }

  hr {
    border: none;
    border-top: 1px solid var(--color--charcoal-300);

    ${MEDIA_QUERIES.M} {
      margin: 45px 0;
    }

    &.small {
      border-top-color: var(--color--charcoal-300);
      border-width: 3px;
      margin: 30px auto;
      width: 30%;

      ${MEDIA_QUERIES.M} {
        border-width: 4px;
        margin: 50px auto;
        width: 20%;
      }
    }
  }
`
