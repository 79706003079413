import memoize from "lodash/memoize"

/*
  Number Utilities
*/
const makeCurrencyFormatter = memoize(() => new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
}))

export const convertNumberToUSD = (n: number, removeDecimal: boolean = false): string => {
  const formatter = makeCurrencyFormatter()
  const result = formatter.format(n)

  if (removeDecimal) {
    return result.split(".")[0]
  }

  return result
}

export const addThousandsSeperator = (n: number): string => {
  return Number(n).toLocaleString()
}

export const removeOperators = (n: number): string => n.toString().replace(/[\/+*-,.]/gi, '')

export const addThousandCommas = (n: number): string => n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")

export const removeNonNumeric = (n: number): string => n.toString().replace(/[^0-9]/g, "")

export const stringToNumber = (s: string): number => Number(s.replace(/,|\.|\D+/g, ""))

export const roundToThousandsWithDecimal = (n: number): string => {
  if (n > 999) {
    return `${(n / 1000).toFixed(1)}k`
  }
  return `${n}`
}