import styled from "styled-components"

import { MEDIA_QUERIES } from "@constants"

export const ContentTableWrapperDIV = styled.div`
  position: relative;
  width: calc(100% + 40px);
  margin-left: -20px;
  margin-bottom: var(--spacing--M);
  overflow: hidden;

  &::before,
  &::after {
    content: "";
    display: block;
    width: 20px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, white 100%);
  }

  &::after {
    left: auto;
    right: 0;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, white 100%);
  }
`

export const ContentTableScrollerDIV = styled.div`
  width: 100%;
  padding: 0 20px;
  overflow: scroll;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`

export const ContentTableTABLE = styled.table`
  width: 100%;
  background: white;
  border-collapse: collapse;
  border: none;
  min-width: 720px;

  thead th {
    background: var(--color--charcoal-200);
  }

  tr:nth-child(even) td {
    background: var(--color--charcoal-100);

    .no-zebra& {
      background: transparent;
    }
  }

  td,
  th {
    padding: 12px;
    text-align: left;
    margin-bottom: -1px;

    ${MEDIA_QUERIES.S} {
      vertical-align: top;
      margin-bottom: 0;
    }
  }

  td {
    border: 1px solid var(--color--charcoal-300);
  }

  th {
    font-weight: 500;
    vertical-align: bottom;
    border: 1px solid var(--color--charcoal-400);
  }

  h1, h2, h3, h4 {
    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  p,
  ul li {
    font-size: var(--font-size--text-S);
  }

  img {
    margin: 30px 0;
  }
`
