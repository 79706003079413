enum BREAKPOINTS_KEYS {
  XS = "XS",
  S = "S",
  M = "M",
  L = "L",
  XL = "XL",
  "2XL" = "2XL",
}

type BREAKPOINTS_TYPE = {
  [k in BREAKPOINTS_KEYS]: number
}

export const BREAKPOINTS: BREAKPOINTS_TYPE = {
  XS: 375,
  S: 480,
  M: 768,
  L: 1024,
  XL: 1600,
  "2XL": 2000,
}

enum MEDIA_QUERIES_KEYS {
  TOUCH = "TOUCH",
  MOUSE = "MOUSE",
}

type MEDIA_QUERIES_TYPE = {
  [k in MEDIA_QUERIES_KEYS | BREAKPOINTS_KEYS]: string
}

export const MEDIA_QUERIES: MEDIA_QUERIES_TYPE = {
  XS: `@media (min-width: ${BREAKPOINTS.XS}px)`,
  S: `@media (min-width: ${BREAKPOINTS.S}px)`,
  M: `@media (min-width: ${BREAKPOINTS.M}px)`,
  L: `@media (min-width: ${BREAKPOINTS.L}px)`,
  XL: `@media (min-width: ${BREAKPOINTS.XL}px)`,
  "2XL": `@media (min-width: ${BREAKPOINTS["2XL"]}px)`,
  TOUCH: `@media (hover: none)`,
  MOUSE: `@media (hover: hover)`,
}
