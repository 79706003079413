import React, { FC, FormEvent, useEffect, useMemo, useRef, useState } from "react"
import { graphql } from "gatsby"

import { HubspotFormFragmentFragment } from "@graphqlTypes"
import { generateConditionalClassName, isClientSide } from "@utils"

import { submitFormData } from "./HubspotForm.utils"
import { HubspotFormDIV } from "./HubspotForm.styles"

import Field, { FieldElements } from "./Field/Field"
import LegalConsentCheckbox from "./LegalConsentCheckbox/LegalConsentCheckbox"
import SubmissionMessage from "./SubmissionMessage/SubmissionMessage"
import HiddenField from "./HiddenField/HiddenField"
import FileUploadField from "./FileUploadField/FileUploadField"

export type HubspotFormProps = {
  isCompact?: boolean,
  onSubmit?: (formEl: HTMLFormElement) => void,
  onSubmitted?: (formEl: HTMLFormElement) => void,
  redirectUrl?: string | null,
  customSuccessMessage?: string,
}

const TRIAL_FORM_ID = "c6177e6a-a687-467a-b2cf-dd8a1daa66f9"

const DEFAULT_SUCCESS_MESSAGE = "Thank you!"

enum FIELD_TYPES {
  dropdown = "dropdown",
  email = "email",
  file = "file",
  multi_line_text = "multi_line_text",
  radio = "radio",
  single_line_text = "single_line_text",
}

const HubspotFormSubmitButton: FC<{
  buttonText?: string | null,
  isCompact?: boolean
}> = ({
  buttonText,
  isCompact,
}) => (
    <button
      className={`button ${isCompact ? "button--M" : "button--L"} submit-button`}
      type="submit"
      name="submit"
    >
      {buttonText || "Submit"}
    </button>
  )

const HubspotForm: FC<HubspotFormFragmentFragment & HubspotFormProps> = ({
  configuration,
  alternative_id,
  displayOptions,
  fieldGroups,
  isCompact,
  legalConsentOptions,
  onSubmit,
  onSubmitted,
  redirectUrl,
  customSuccessMessage,
}) => {
  if (!alternative_id || !fieldGroups || fieldGroups.length === 0) return null

  const formRef = useRef<HTMLFormElement | null>(null)

  // If a field.name is invalid, set to `true`
  const [inputValidationMap, setInputValidationMap] = useState<{ [name: string]: boolean }>({})

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [successMessage, setSuccessMessage] = useState<string | undefined>()
  const [errorMessage, setErrorMessage] = useState<string | undefined>()
  const isSubmitted: boolean = !!(successMessage || errorMessage)

  const isEmailCatcher: boolean = useMemo(() => {
    const hasEmailField = !!fieldGroups?.find(fieldGroup =>
      fieldGroup?.alternative_fields?.find(field => field?.name === "email")
    )

    const hasMultipleInputs: boolean = fieldGroups.reduce((acc: boolean, fieldGroup) => {
      if (acc) return acc

      if (
        !fieldGroup ||
        !fieldGroup.alternative_fields ||
        fieldGroup.alternative_fields[0]?.fieldType === "email"
      ) return false

      return fieldGroup.alternative_fields.filter(field =>
        field &&
        !field.hidden &&
        Object.keys(FIELD_TYPES).filter(t => t !== "file").includes(field.fieldType || "")
      ).length > 0
    }, false)

    return hasEmailField && !hasMultipleInputs
  }, [fieldGroups])

  // Set the form to `novalidate` so that we can implement our own validation checking
  useEffect(() => {
    if (!isClientSide() || !formRef.current) return
    formRef.current.noValidate = true
  }, [])

  // Validate an individual field
  const validateField = (el: FieldElements) => {
    setInputValidationMap(_ivm => ({
      ..._ivm,
      [el.name]: !el.checkValidity()
    }))
  }

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (!formRef.current) return

    // If the form is invalid, check the validity of each field and put these validations in the inputValidationMap
    if (!formRef.current.checkValidity()) {
      const inputs = formRef.current.querySelectorAll<FieldElements>(
        "input:not([hidden]), textarea:not([hidden]), select:not([hidden]), input.fileUploadField"
      )
      const _ivm = Array.from(inputs).reduce((acc, input) => ({ ...acc, [input.name]: !input.checkValidity() }), {})
      setInputValidationMap(_ivm)
      inputs[0].focus()
      return
    }

    if (onSubmit) onSubmit(formRef.current)

    setIsSubmitting(true)

    try {
      await submitFormData(formRef.current, alternative_id)
        .then(json => {
          if (!json.success) throw new Error(json.message)

          setIsSubmitting(false)
          setSuccessMessage(customSuccessMessage || configuration?.postSubmitAction?.value || DEFAULT_SUCCESS_MESSAGE)

          if (formRef.current && onSubmitted) onSubmitted(formRef.current)

          if (alternative_id === TRIAL_FORM_ID) {
            const trialUrl = document.querySelector<HTMLAnchorElement>("a#free-trial-link")?.href
            if (trialUrl) window.open(trialUrl, '_blank')
            return
          }

          if (redirectUrl) {
            window.open(redirectUrl, '_blank')
            return
          }
        })
    } catch (err) {
      setIsSubmitting(false)
      setErrorMessage(err.message)
    }
  }

  return (
    <HubspotFormDIV
      className={generateConditionalClassName({
        "HubspotForm max-width--column--6": true,
        "is-email-catcher": isEmailCatcher,
        "is-compact": !!isCompact,
      })}
    >

      <form
        className="flex"
        encType="multipart/form-data"
        method="POST"
        onSubmit={handleSubmit}
        ref={formRef}
        data-hubspot-id={alternative_id}
      >
        {legalConsentOptions?.consentToProcessText && (
          <input type="hidden" name={`legal--consentToProcess__TEXT`} value={legalConsentOptions.consentToProcessText} />
        )}
        {legalConsentOptions?.communicationsCheckboxes && legalConsentOptions?.communicationsCheckboxes.length > 1 && (
          <>
            {legalConsentOptions.communicationsCheckboxes.map(checkbox => !checkbox ? null : (
              <LegalConsentCheckbox
                {...checkbox}
                hasMultipleOptions
                isCompact={isCompact}
                isDisabled={isSubmitted}
                isInvalid={inputValidationMap[`legal--${checkbox.subscriptionTypeId}`]}
                isSubscription
                key={checkbox.subscriptionTypeId}
              />
            ))}
          </>
        )}
        {fieldGroups?.map((fieldGroup, i) => (!fieldGroup || !fieldGroup.alternative_fields) ? null : (
          <div
            className={generateConditionalClassName({
              flex: true,
              "email-field": !!fieldGroup.alternative_fields.find(field => field?.fieldType === FIELD_TYPES["email"]),
            })}
            key={`fieldGroup--${i}`}
            style={{ display: fieldGroup.alternative_fields.find(field => field?.hidden) ? "none" : "" }}
          >
            {fieldGroup.alternative_fields.map(field => {
              if (!field) return null
              const fieldId = `${alternative_id}-${field.name}`
              // Hidden fields
              if (field.hidden) {
                return (
                  <HiddenField
                    defaultValue={field.defaultValue}
                    defaultValues={field.defaultValues}
                    key={fieldId}
                    id={fieldId}
                    name={field.name || ""}
                  />
                )
              }

              if (field.fieldType === FIELD_TYPES["file"]) {
                return (
                  <FileUploadField
                    className={`flex-item--${fieldGroup.alternative_fields?.length || 1}`}
                    key={fieldId}
                    id={fieldId}
                    isCompact={isCompact}
                    isDisabled={isSubmitted}
                    isInvalid={field.name ? inputValidationMap[field.name] : false}
                    field={field}
                    validateField={validateField}
                  />
                )
              }

              // Email / text fields
              if (
                field.fieldType &&
                Object.keys(FIELD_TYPES).includes(field.fieldType)
              ) {
                return (
                  <React.Fragment key={fieldId}>
                    {isEmailCatcher && field.fieldType === FIELD_TYPES["email"] && (successMessage || errorMessage || isSubmitting) ? (
                      <SubmissionMessage
                        successMessage={successMessage}
                        errorMessage={errorMessage}
                        isSubmitting={isSubmitting}
                      />
                    ) : (
                      <>
                        <Field
                          className={`flex-item--${fieldGroup.alternative_fields?.length || 1}`}
                          key={fieldId}
                          id={fieldId}
                          isCompact={isCompact}
                          isDisabled={isSubmitted}
                          isInvalid={field.name ? inputValidationMap[field.name] : false}
                          field={field}
                          validateField={validateField}
                        />
                        {isEmailCatcher && field.fieldType === FIELD_TYPES["email"] && (
                          <HubspotFormSubmitButton
                            buttonText={displayOptions?.submitButtonText}
                            isCompact={isCompact}
                          />
                        )}
                      </>
                    )}
                  </React.Fragment>
                )
              }

              return null
            })}
          </div>
        ))}
        {legalConsentOptions?.communicationsCheckboxes && legalConsentOptions?.communicationsCheckboxes.length === 1 && (
          <>
            {legalConsentOptions.communicationsCheckboxes.map(checkbox => !checkbox ? null : (
              <LegalConsentCheckbox
                {...checkbox}
                isCompact={isCompact}
                isDisabled={isSubmitted}
                isInvalid={inputValidationMap[`legal--${checkbox.subscriptionTypeId}`]}
                key={checkbox.subscriptionTypeId}
              />
            ))}
          </>
        )}
        {!isEmailCatcher && (
          (successMessage || errorMessage || isSubmitting) ? (
            <SubmissionMessage
              successMessage={successMessage}
              errorMessage={errorMessage}
              isSubmitting={isSubmitting}
            />
          ) : (
            <HubspotFormSubmitButton
              buttonText={displayOptions?.submitButtonText}
              isCompact={isCompact}
            />
          )
        )}
        {(legalConsentOptions?.privacyText || legalConsentOptions?.consentToProcessText) && (
          <div
            className="privacy-text"
            dangerouslySetInnerHTML={{ __html: legalConsentOptions.privacyText || legalConsentOptions.consentToProcessText || "" }}
          />
        )}
      </form>
    </HubspotFormDIV>
  )
}

export const HubspotFormFragment = graphql`
  fragment HubspotFormInputFragment on HubspotFormFieldGroupsAlternative_fields {
    name
    placeholder
    required
    fieldType
    description
    defaultValue
    defaultValues
    label
    hidden
    objectTypeId
    options {
      description
      displayOrder
      label
      value
    }
    useCountryCodeSelect
    validation {
      blockedEmailDomains
      maxAllowedDigits
      minAllowedDigits
      useDefaultBlockList
    }
  }
  fragment HubspotFormLegalCheckboxFragment on HubspotFormLegalConsentOptionsCommunicationsCheckboxes {
    label
    required
    subscriptionTypeId
  }
  fragment HubspotFormFragment on HubspotForm {
    name
    alternative_id
    displayOptions {
      submitButtonText
    }
    configuration {
      postSubmitAction {
        type
        value
      }
    }
    fieldGroups {
      alternative_fields {
        ...HubspotFormInputFragment
      }
      groupType
      richText
      richTextType
    }
    legalConsentOptions {
      communicationsCheckboxes {
        ...HubspotFormLegalCheckboxFragment
      }
      consentToProcessText
      privacyText
    }
  }
`

export default HubspotForm
