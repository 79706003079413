export const HIDE_FOOTER_PATHS = [
  "/product-tour/",
]

export const USE_SMALL_FOOTER_PATHS = [
  "/fundraising-with-purpose/",
  "/interview-builder/",
  "/lp/personalized-customer-experience",
  "/webinars/foundations-of-great-service/",
]

export const USE_STATIC_HEADER_PATHS = [
  "/interview-builder/",
  "/pricing/",
  "/pricing/contact-based/",
]

export const INCLUDE_FIXED_CTA_BAR_PATHS = [
  "/pricing/",
  "/platform/",
  "/shared-inbox/",
  "/customer-support/",
  "/customer-champions/",
  "/inbox/",
  "/knowledge-base/",
  "/beacon-messaging/",
  "/help-desk-workflows/",
  "/customer-management/",
  "/collaboration/",
  "/customer-support-channels/",
  "/proactive-messages/",
  "/ai-features/",
  "/analytics/",
  "/integrations/",
  "/industry/.+",
  "/compare/.+",
  "/lp/get-a-demo/",
  "/customer-referral/.+",
  "/for-good/",
  "/startups/",
]

export const INCLUDE_FIXED_CTA_BAR_PATHS_REGEX = new RegExp(INCLUDE_FIXED_CTA_BAR_PATHS.join("|"))
