import xss from "xss"

/*
  String Utilities
*/
export const isString = (str: string): boolean =>
  Object.prototype.toString.call(str) === "[object String]"

export const oneLineHelpScout = (str: string): string =>
  !isString(str) ? "" : xss(str.replace("Help Scout", "Help&nbspScout"))

export const removeParagraphs = (str: string): string =>
  !isString(str) ? "" : str.replace(/(<p[^>]+?>|<p>|<\/p>)/gim, "")

// Adds an `&nbsp` between the next-to-last and last word. Can be used to prevent ~~widows~~ Sleepy Buddies in headlines/titles.
export const preventSleepyBuddies = (str: string): string => {
  if (typeof str !== "string") return ""

  const LONG_WORD_LENGTH = 5
  const words = str.split(" ")
  const lastTwoWords = words.slice(-2)
  const hasLongLastTwoWords = lastTwoWords.some((word) => word.length > LONG_WORD_LENGTH)

  if (hasLongLastTwoWords) {
    return str
  }

  const wordsWithSpace = words.map((word, index) => {
    if (index === words.length - 2) {
      return word + "&nbsp;"
    } else if (index === words.length - 1) {
      return word
    } else {
      return word + " "
    }
  })

  return wordsWithSpace.join("")
}

export const convertKebabToTitleCase = (str: string): string =>
  !isString(str)
    ? ""
    : str
      .split("-")
      .map((word) =>
        ["and", "at"].includes(word)
          ? word
          : `${word.charAt(0).toUpperCase()}${word.substring(1)}`
      )
      .join(" ")

export const convertStringToKebabCase = (str: string): string => {
  if (!isString(str) || str.length === 0) return ""

  const matches = str.match(
    /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g
  )

  if (!matches) return ""

  return matches.map((x) => x.toLowerCase()).join("-")
}

export const convertStringToSlug = (str: string) => {
  return str
    .toLowerCase()
    .replace(/[^\w\s-]/g, '') // Remove non-word characters
    .replace(/\s+/g, '-') // Replace whitespace with dashes
    .replace(/--+/g, '-') // Replace consecutive dashes with a single dash
    .trim() // Trim leading/trailing whitespace and dashes
}

export const hasTrailingSlash = (slug: string): boolean =>
  isString(slug) && slug?.slice(-1) === "/"

// tiny port of classnames module
// accepts an object like: `{ "class-one": var === condition, "class-two": var === conditional2 }`
// and returns: `class-one class-two` / `class-two` / etc.
export const generateConditionalClassName = (classNames: { [k: string]: boolean }): string =>
  Object.keys(classNames).reduce((acc, className) =>
    `${acc}${classNames[className] ? ` ${className}` : ""}`
  , "")
