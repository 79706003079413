import React, { FC, useEffect, useState } from "react"
import { globalHistory } from "@reach/router"
import { GatsbyImage } from "gatsby-plugin-image"

import { getImgixImageData, isClientSide } from "@utils"

import Button, { BUTTON_SIZES } from "@components/Button/Button"
import Icon, { ICON_TYPES } from "@components/Icon/Icon"

import { FixedCtaBarDIV } from "./FixedCtaBar.styles"

const FIXED_CTA_BAR_LS_KEY = `hs--fixed-cta-bar--is-dismissed`
const ZI_DATA_LS_KEY = `_ziVisitorInfo`

const PATHNAMES_TO_HIDE_ON = [
  "/lp/get-a-demo/",
  "/pricing/contact-based/",
]

const FixedCtaBar: FC<{ forceShow?: boolean }> = ({ forceShow = false }) => {
  const [isVisible, setIsVisible] = useState<boolean>(false)
  const [shouldShow, setShouldShow] = useState<boolean>(forceShow)

  useEffect(() => {
    if (!isClientSide()) return

    if (window.location.search.includes("show_fixed_cta_bar=1")) {
      setShouldShow(true)
      return
    }

    const isDismissed = window.localStorage.getItem(FIXED_CTA_BAR_LS_KEY)

    if (isDismissed) return

    const visitorData = JSON.parse(window.localStorage.getItem(ZI_DATA_LS_KEY) || "{}")

    if (
      !visitorData.ziDetails ||
      !visitorData.ziDetails.employeeCount ||
      visitorData.ziDetails.employeeCount < 11 ||
      !visitorData.ziDetails.country ||
      ["India", "Russia", "China"].includes(visitorData.ziDetails.country)
    )
      return

    setShouldShow(true)
  }, [])

  useEffect(() => {
    if (!isClientSide() || !shouldShow) return

    if (forceShow) {
      setIsVisible(true)
      return
    }

    const handleScroll = () => {
      if (window.scrollY >= document.body.scrollHeight / 3 - window.innerHeight) {
        setIsVisible(true)
        window.removeEventListener("scroll", handleScroll)
      }
    }

    window.addEventListener("scroll", handleScroll)
    globalHistory.listen(action => {
      if (!action) return

      if (
        action.action === "PUSH" &&
        !PATHNAMES_TO_HIDE_ON.includes(action.location.pathname)
      )
        setIsVisible(false)
    })

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [shouldShow])

  const handleClickClose = () => {
    window.localStorage.setItem(FIXED_CTA_BAR_LS_KEY, "true")
    setIsVisible(false)
  }

  return (
    <FixedCtaBarDIV className={isVisible ? "is-visible" : ""}>
      <div className="gradient-border" />
      <div className="content">
        <GatsbyImage
          alt="Avatars of members of our Sales Team"
          image={getImgixImageData("/images/components/FixedCtaBar/FixedCtaBar--avatars.png", 112, 112 / 40)}
        />
        <p className="font-size--L">We’d love to show you the magic of Help&nbsp;Scout.</p>
        <Button
          className="desktop"
          href="/lp/get-a-demo/?demofixedcta=true"
          onClick={handleClickClose}
          size={BUTTON_SIZES.S}
        >
          Book a Demo
        </Button>
        <Button
          className="mobile"
          size={BUTTON_SIZES.M}
          href="/lp/get-a-demo/?demofixedcta=true"
          onClick={handleClickClose}
        >
          Book a Demo
        </Button>
        <button
          className="close-button"
          onClick={handleClickClose}
        >
          <Icon
            color="var(--color--basics-white)"
            type={ICON_TYPES["close"]}
          />
        </button>
      </div>
    </FixedCtaBarDIV>
  )
}

export default FixedCtaBar
