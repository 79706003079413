enum URLS_AND_NUMBERS_KEYS {
  ANDROID_URL = "ANDROID_URL",
  DEMO_URL = "DEMO_URL",
  EMAIL_SUBS = "EMAIL_SUBS",
  IOSAPP_URL = "IOSAPP_URL",
  LOGIN_URL = "LOGIN_URL",
  REMOTE_CITIES = "REMOTE_CITIES",
  SECURE_URL = "SECURE_URL",
  SIGNUP_PLUS_URL = "SIGNUP_PLUS_URL",
  SIGNUP_STANDARD_URL = "SIGNUP_STANDARD_URL",
  TOTAL_CUST = "TOTAL_CUST",
  TOTAL_CUST_COUNTRIES = "TOTAL_CUST_COUNTRIES",
  TRIAL_DAYS_LENGTH = "TRIAL_DAYS_LENGTH",
  WEBINARS_URL = "WEBINARS_URL",
}
const URLS_AND_NUMBERS: { [key in URLS_AND_NUMBERS_KEYS]: string } = {
  ANDROID_URL: "https://play.google.com/store/apps/details?id=net.helpscout.android",
  DEMO_URL: "/lp/get-a-demo/",
  EMAIL_SUBS: "251,101",
  IOSAPP_URL: "https://itunes.apple.com/us/app/help-scout/id966327660?ls=1&mt=8",
  LOGIN_URL: "https://secure.helpscout.net/",
  REMOTE_CITIES: "75",
  SECURE_URL: "https://secure.helpscout.net",
  SIGNUP_PLUS_URL: "https://secure.helpscout.net/members/register/49/",
  SIGNUP_STANDARD_URL: "https://secure.helpscout.net/members/register/48/",
  TOTAL_CUST: "12,000+",
  TOTAL_CUST_COUNTRIES: "140",
  TRIAL_DAYS_LENGTH: "15",
  WEBINARS_URL: "/webinars/",
}

export default URLS_AND_NUMBERS
