/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import React from "react"
import type { GatsbyBrowser } from "gatsby"

import { init as initAttributionTracking } from "@helpscout/attributionjs"

import Layout from "./src/components/Layout/Layout"

import OptionalScripts from "./src/components/ThirdPartyScripts/OptionalScripts/OptionalScripts"
import PartnerStackCookies from "./src/components/ThirdPartyScripts/PartnerStackCookies/PartnerStackCookies"

declare global {
  interface Window {
    clearbit: any
    ga: any
    mixpanel: any
    Beacon: any
  }
}

export const onInitialClientRender: GatsbyBrowser["onInitialClientRender"] =
  () => {
    initAttributionTracking()
  }

export const onRouteUpdate: GatsbyBrowser["onRouteUpdate"] = () => {
  initAttributionTracking()

  if (window.clearbit) {
    window.clearbit.page()
  }

  // Send a "Viewed Page" event to Mixpanel when the route changes
  if (window.mixpanel) {
    // TODO: Implement variation tracking once it's needed
    // var variation = window.HS && window.HS.Ab ? window.HS.Ab.getTestDetailsFromCookie() : '';
    window.mixpanel.track("Viewed Page", {
      // variation: variation
    })
  }
}

export const wrapPageElement: GatsbyBrowser["wrapPageElement"] = ({
  element,
  props,
}) => {
  return (
    <>
      <PartnerStackCookies />
      <OptionalScripts path={props.location.pathname} />
      <Layout
        path={props.location.pathname}
        template={props.pageContext.template as string}
      >
        {element}
      </Layout>
    </>
  )
}
