import { getContrast } from "polished"
import { FlattenSimpleInterpolation, css } from "styled-components"

import {
  BREAKPOINTS,
  MEDIA_QUERIES,
} from "@constants"

/*
  CSS Utilities
*/
export const generateFluidCalc = (
  minValue: number,
  maxValue: number,
  viewportMin: number,
  viewportMax: number
) => {
  const minValueRem = minValue / 16
  const maxValueRem = maxValue / 16
  const fluidVwValue = ((100 * ((maxValue) - (minValue))) / (viewportMax - viewportMin)).toFixed(6)
  const fluidRemValue = Math.abs(((((minValue * viewportMax) - (maxValue * viewportMin)) / (viewportMin - viewportMax)) / 16)).toFixed(6)

  return `clamp(${minValueRem}rem, ${fluidVwValue}vw + ${fluidRemValue}rem, ${maxValueRem}rem)`
}

export const shouldUseLightText = (backgroundColor: string): boolean => getContrast(backgroundColor, "white") > 2

export const getResponsiveFontSize = (min: number, max: number, important: boolean = false): string | FlattenSimpleInterpolation =>
  min === max ?
    `font-size: ${max}px;` :
    css`
      font-size: ${min}px${important ? ' !important' : ''};

      ${MEDIA_QUERIES.S} {
        font-size: calc(${min}px + (${max} - ${min}) * ((100vw - ${BREAKPOINTS.S}px) / (${BREAKPOINTS.XL} - ${BREAKPOINTS.S})))${important ? ' !important' : ''};
      }

      ${MEDIA_QUERIES.XL} {
        font-size: ${max}px${important ? ' !important' : ''};
      }
    `

// Get column width as a %
// Based on our 12-column grid
export const getColumnPercentWidth = (numberOfColumns: number): string => (100 / 12 * numberOfColumns).toPrecision(6)

export const getFocusStateCSS = (
  excludeBackground: boolean = false,
  useDarkBackground: boolean = false,
): FlattenSimpleInterpolation => css`
  ${!excludeBackground && !useDarkBackground && `background: var(--color--cobalt-100);`}
  ${useDarkBackground && `background: var(--color--charcoal-1000);`}
  border-radius: 4px;
  box-shadow:
    0 0 0 2px ${useDarkBackground ? `var(--color--charcoal-1100)` : "white"},
    0 0 0 4px var(--color--cobalt-600);
`

enum CARET_DIRECTION_OPTIONS {
  DEFAULT = "RIGHT",
  RIGHT = "RIGHT",
  DOWN = "DOWN",
}

export const getCaretCSS = (direction?: CARET_DIRECTION_OPTIONS): FlattenSimpleInterpolation => css`
  border-top-width: 2px;
  border-top-style: solid;
  border-right-width: 2px;
  border-right-style: solid;
  content: "";
  display: inline-block;
  margin-left: 0.3em;
  position: relative;
  pointer-events: none;
  width: 6px;
  height: 6px;
  transform: rotate(45deg);
  transition: all 0.15s;
  vertical-align: middle;

  ${direction === "DOWN" && `
    transform: rotate(135deg);
  `}
`
