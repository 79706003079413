export const INCLUDE_JQUERY_PATHS = [
  "/",
  "/classes/",
  "/help-desk-staffing-calculator/"
]

export const INCLUDE_UNIFY_PATHS = [
  "/ai-features/",
  "/analytics/",
  "/beacon-messaging/",
  "/collaboration/",
  "/compare/",
  "/customer-management/",
  "/customer-referral/",
  "/customer-support-channels/",
  "/customer-support/",
  "/help-desk-integration/",
  "/help-desk-workflows/",
  "/inbox/",
  "/industry/",
  "/knowledge-base/",
  "/lp/get-a-demo/",
  "/platform/",
  "/pricing/",
  "/proactive-messages/",
  "/shared-inbox/",
  // BOFU
  "/blog/ecommerce-tools/",
  "/playlists/customer-service-software/",
  "/helpu/ecommerce-help-desk/",
  "/blog/ecommerce-customer-service-software/",
  "/blog/zendesk-alternatives/",
  "/helpu/choosing-help-desk-software/",
  "/help-desk-ticketing-system/",
  "/blog/outlook-shared-mailbox-alternatives/",
  "/blog/kayako-alternatives/",
  "/blog/happyfox-alternatives/",
  "/blog/freshdesk-alternatives/",
  "/blog/hubspot-alternatives/",
  "/blog/liveagent-alternatives/",
  "/blog/help-scout-alternatives/",
  "/blog/kustomer-alternatives/",
  "/blog/help-scout-vs-intercom/",
  "/blog/intercom-alternatives/",
  "/blog/front-alternatives/",
  "/blog/team-email-management/",
  "/blog/knowledge-base-software/",
  "/blog/shared-inbox-gmail/",
  "/blog/shared-inbox/",
  "/blog/best-email-management-software/",
  "/blog/distribution-list-vs-shared-mailbox/",
  "/blog/ai-customer-support-software/",
  "/blog/help-scout-setup-guide/",
  "/blog/jira-ticketing-system/",
  "/blog/shared-email-account/",
  "/blog/best-email-management-software/",
  "/blog/live-chat-tools/",
  "/blog/knowledge-management-tools/",
  "/blog/9-best-live-chat-widgets/",
  "/blog/creating-a-knowledge-base/",
  "/blog/email-ticketing-system/",
  "/blog/choosing-support-tools/",
  // MOFU
  "/talking-to-customers/",
  "/blog/customer-service-skills/",
  "/blog/customer-service-interview-questions/",
  "/blog/shared-instagram-account/",
  "/blog/customer-service-phrases/",
  "/blog/good-customer-service/",
  "/blog/customer-service-scenarios/",
  "/blog/customer-service-goals/",
  "/blog/customer-feedback/",
  "/blog/ai-for-email/",
  "/blog/how-to-write-an-auto-reply-email/",
  "/blog/customer-complaints-examples/",
  "/blog/customer-service-resume-examples/",
  "/blog/real-estate-customer-service/",
  "/blog/customer-success-plan-templates/",
  "/blog/customer-onboarding/",
  "/blog/customer-success-manager-career-paths/",
  "/blog/customer-service-vs-customer-support/",
  "/blog/ai-knowledge-base/",
  "/blog/ecommerce-survey-questions/",
  "/blog/customer-survey/",
  "/blog/customer-loyalty-programs/",
  "/blog/hipaa-compliant-messaging-app/",
  "/blog/increase-team-productivity/",
  "/blog/customer-perception/",
  "/blog/the-importance-of-b2b-service/",
  "/blog/customer-experience-examples/",
  "/blog/benefits-of-ai-in-customer-service/",
  "/blog/customer-onboarding-email-templates/",
  "/blog/time-to-resolution/",
  "/blog/customer-success-manager/",
  "/blog/save-support-time-with-ai/",
  "/blog/customer-relations/",
  "/blog/voice-of-customer/",
  "/blog/ai-chatbot-examples/",
  "/blog/customer-satisfaction/",
  "/blog/customer-experience-kpis/",
  "/helpu/knowledge-base-examples/",
]
