import React, { FC, ReactNode } from "react"

import { TextLinkA, TextLinkLINK, TextLinkSPAN } from "./TextLink.styles"
import { generateConditionalClassName } from "@utils"

export enum TEXT_LINK_SIZES {
  "XS" = "XS",
  "S" = "S",
  "M" = "M",
  "L" = "L",
}

export type TextLinkProps = {
  children: ReactNode
  className?: string
  href?: string | null
  onClick?: React.MouseEventHandler<HTMLAnchorElement>
  size?: TEXT_LINK_SIZES
  useDarkMode?: boolean | null
  rotateIcon?: boolean | null
}

const TextLinkCaretSVG = () => (
  <svg
    width="9"
    height="13"
    viewBox="0 0 9 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.23684 6.36842L2.67193 0.578948L1 2.31835L4.91233 6.34829L1 10.4185L2.67193 12.1579L8.23684 6.36842Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0.405263"
    />
  </svg>
)

const TextLink: FC<TextLinkProps> = ({
  children,
  className,
  href,
  onClick,
  size = TEXT_LINK_SIZES.M,
  useDarkMode,
  rotateIcon,
}) => {
  const _className = generateConditionalClassName({
    TextLink: true,
    [`${className}`]: !!className,
    [`font-size--${size}`]: true,
    "use-dark-mode": !!useDarkMode,
    "use-rotate-svg": !!rotateIcon,
  })

  return !href ? (
    <TextLinkSPAN
      className={_className}
      onClick={onClick}
    >
      {children}
      <TextLinkCaretSVG />
    </TextLinkSPAN>
  ) : !(href?.includes("//") || !!href?.match(/^#/)) ? (
    <TextLinkLINK
      className={_className}
      onClick={onClick}
      to={href}
    >
      {children}
      <TextLinkCaretSVG />
    </TextLinkLINK>
  ) : (
    <TextLinkA
      className={_className}
      href={href}
      onClick={onClick}
      target={href?.match(/^#/) ? undefined : "_blank"}
    >
      {children}
      <TextLinkCaretSVG />
    </TextLinkA>
  )
}

export default TextLink
