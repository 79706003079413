import React, { ChangeEvent, FC, FocusEvent, useEffect, useRef, useState } from "react"
import debounce from "lodash/debounce"
import styled from "styled-components"

import { HubspotFormInputFragmentFragment } from "@graphqlTypes"
import { generateConditionalClassName, isClientSide } from "@utils"

import ValidationIcon from "../ValidationIcon/ValidationIcon"

const FieldLABEL = styled.label`
  position: relative;

  .input-wrapper {
    position: relative;
  }
  
  &.is-disabled {
    pointer-events: none;
    cursor: default;
  }

  select.has-error {
    background-position: calc(100% - var(--input--padding-x-M) - var(--spacing--base-0-5) - var(--font-size--M) * var(--line-height--relaxed)) 50%;
  }
`

export type FieldElements = HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement

const InputTypeMap: { [k: string]: string } = {
  email: "email",
  file: "file",
  single_line_text: "text",
}

type FieldProps = {
  className?: string,
  field: HubspotFormInputFragmentFragment,
  id: string,
  isCompact?: boolean,
  isDisabled?: boolean,
  isInvalid: boolean,
  validateField: (el: FieldElements) => void,
}

const getBlockedEmailDomainsRegexList = (blockedEmailDomains?: (string | null)[] | null): string => {
  if (!blockedEmailDomains || blockedEmailDomains.length === 0) return ""

  const domains = blockedEmailDomains?.map(domain => domain?.trim().replace(".", "\\.") || "").filter(v => v)

  if (domains.length === 0) return ""

  return `(?!(${domains.join("|")}))`
}

const Field: FC<FieldProps> = ({
  className,
  field,
  id,
  isCompact,
  isDisabled,
  isInvalid,
  validateField,
}) => {
  const debouncedValidateField = useRef<FieldProps["validateField"] | null>(null)
  const [value, setValue] = useState<string>("")

  useEffect(() => {
    if (!isClientSide()) return

    debouncedValidateField.current = debounce(validateField, 200)
  }, [])

  const handleChange = (e: ChangeEvent<FieldElements>) => {
    setValue(e.target.value)
    if (isInvalid && debouncedValidateField.current) debouncedValidateField.current(e.target)
  }

  const InputProps = {
    "data-object-type-id": field.objectTypeId,
    className: generateConditionalClassName({
      "has-error": isInvalid,
      "size--M": !!isCompact,
    }),
    disabled: isDisabled,
    id: id,
    name: field.name || "",
    onBlur: (e: FocusEvent<FieldElements>) => validateField(e.target),
    onChange: handleChange,
    placeholder: field.placeholder || "",
    required: !!field.required,
    value: value || field.defaultValue || "",
  }

  const FieldLabelClass = generateConditionalClassName({
    [`${className}`]: true,
    "is-disabled": !!isDisabled,
  })

  const LabelClassName = generateConditionalClassName({
    "font-size--S margin-bottom--base-0-5": true,
    "color--text-light": !isDisabled,
    "color--text-disabled": !!isDisabled,
  })

  // Radio buttons are their own weird thing:
  if (field.fieldType === "radio") {
    return (
      <FieldLABEL as="div" className={FieldLabelClass}>
        <div className={LabelClassName}>
          {field.label}
          {isInvalid && <ValidationIcon fieldName={field.name || ""} />}
        </div>
        {(field.options || []).map((option, i) => !option ? null : (
          <label
            key={option.value}
            className={`flex${i + 1 < (field.options || []).length ? " margin-bottom--base-0-5" : ""}`}
            style={{ alignItems: "center" }}
          >
            <input
              {...InputProps}
              type="radio"
              value={option.value || ""}
            />
            <span>{option.label}</span>
          </label>
        ))}
      </FieldLABEL>
    )
  }

  // Every other kind of input can fit in here:
  return (
    <FieldLABEL className={FieldLabelClass}>
      {field.label && <span className={LabelClassName}>{field.label}</span>}
      <div className="input-wrapper">
        {(
          field.fieldType === "single_line_text" ||
          field.fieldType === "email"
        ) && (
            <input
              {...InputProps}
              type={field.fieldType ? (InputTypeMap[field.fieldType] || "text") : "text"}
              pattern={field.fieldType === "email" ? (
                `.+@${getBlockedEmailDomainsRegexList(field.validation?.blockedEmailDomains)}.*\\..*`
              ) : undefined}
            />
          )}
        {field.fieldType === "multi_line_text" && (
          <textarea
            {...InputProps}
          />
        )}
        {field.fieldType === "dropdown" && (
          <select {...InputProps}>
            <option disabled value="">{field.placeholder}</option>
            {(field.options || []).map(option => !option ? null : (
              <option key={option.value || ""} value={option.value || ""}>{option?.label}</option>
            ))}
          </select>
        )}
        {isInvalid && (
          <ValidationIcon
            fieldName={field.name || ""}
            message={field.fieldType === "email" ? "Please enter a valid email address" : undefined}
          />
        )}
      </div>
    </FieldLABEL>
  )
}

export default Field
